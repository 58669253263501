.wrapper {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  pointer-events: auto;
}

.imageWrapper {
  width: 150px;
  height: 150px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
}

.selected {
    border-color: deepskyblue;
    border-width: 2px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.pagination {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}